<template>
    <div class="daily">
        <div class="daily-content">
            <div class="select-topic">
                <div class="option-header">
                    <div class="select-wrap">
                        <div
                                class="select-type select-btn"
                                v-for="(v ,i) in selectList"
                                @click="checkQueryType(v.id)"
                                :class="{'selected-active':checkQueryIdx == v.id }"
                                :key="i"
                        >
                            <span v-text="v.val"></span>
                        </div>
                    </div>
                </div>
                <div class="option-body">
                    <div class="select-date clear" @click="openPicker()">
                        <div class="date left" v-text="dateObj.date"></div>
                        <div class="drop-down-btn left"></div>
                    </div>
                    <div class="select-country" v-if="hasQgPerm && hasCenter">
                        <div
                                class="select-txt selected-country"
                                :class="[selectAreaType == 0 ?'whiteFont' : '']"
                                @click="setActiveBySelected(0)"
                        >全国
                        </div>
                        <div
                                class="select-txt selected-center"
                                :class="[selectAreaType == 1 ?'whiteFont' : '']"
                                @click="setActiveBySelected(1)"
                        >本中心
                        </div>
                        <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                    </div>
                    <div class="select-country show-center-box" v-if="!hasQgPerm && hasCenter">
                        <div
                                class="select-txt selected-center show-center"
                                :class="[selectAreaType == 1 ?'whiteFont' : '']"
                                @click="setActiveBySelected(1)"
                        >本中心
                        </div>
                        <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                    </div>
                    <div class="select-country show-center-box" v-if="hasQgPerm && !hasCenter">
                        <div
                                class="select-txt selected-center show-center"
                                :class="[selectAreaType == 0 ?'whiteFont' : '']"
                                @click="setActiveBySelected(0)"
                        >全国
                        </div>
                        <div class="check-btn check-btn-run-1"></div>
                    </div>
                </div>
                <div class="option-bottom">
                    <ul class="sales-txt-wrap clear">
                        <li class="sales-text-bottom-line" :class="'check2-line-run-' + selectSalesType"></li>
                        <li class="sales-type" @click="setClassNameBySelectSales(1)">
                            <p>门店总数</p>
                            <p v-text="shopCnt"></p>
                        </li>
                        <li class="sales-type" @click="setClassNameBySelectSales(0)">
                            <p>新增门店数</p>
                            <p v-text="addShopCnt"></p>
                        </li>
                        <li class="sales-type" @click="setClassNameBySelectSales(2)">
                            <p>有销量门店数</p>
                            <p v-text="hasSaleCnt"></p>
                        </li>
                    </ul>
                </div>
                <mt-datetime-picker
                        ref="picker"
                        v-model="pickerVisible"
                        type="date"
                        year-format="{value} 年"
                        month-format="{value} 月"
                        @confirm="handleConfirm()"
                        :endDate="getYesterday"
                ></mt-datetime-picker>
            </div>
            <!--销量统计-->
            <moduleTitle :title="salesTit"></moduleTitle>
            <salesLine :option="SalesOptin" :loading="SalesloadingType"
                       :DataType="SalesHasDataType"
                       :salesCanvasSize="salesCanvasSize"></salesLine>
            <!--地区统计折线-->
            <moduleTitle :title="areaTit"></moduleTitle>
            <salesLine :option="AreaOptin" :salesCanvasSize="AreaCanvasSize" :loading="ArealoadingType"
                       :DataType="AreaHasDataType"></salesLine>
            <!--地区统计饼状图-->
            <div class="dataBox">
                <div class="dataBox_0 clear">
                    <div class="clear" style="margin-left: 1.5rem">
                        <salesLine :option="cityOptin" :salesCanvasSize="cityCanvasSize" :loading="ArealoadingType"
                                   :DataType="CityHasDataType"></salesLine>
                    </div>
                    <div class="chart_desc">
                        <div v-for="(v,i) in locArr" v-if="i < showlocIndex" :key="i">
                            <i :style="{background:colorArr[i]}"></i>
                            <span class="name_city" v-text="v.name"></span>
                            <span class="num_city" v-text="numFormat(v.cnt) +'家'"></span>
                            <span class="num_percent" v-text="getPercent(v.cnt,allLocSales)"></span>
                        </div>
                        <div class="linkToAll" @click="showAllLoc" v-if="this.locArr.length >3" v-text="tips"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import salesLine from "@/components/salesLine.vue";
    import moduleTitle from "@/components/moduleTitle";
    import {MessageBox} from 'mint-ui';

    export default {
        name: "daily",
        components: {
            salesLine,
            moduleTitle
        },
        data () {
            let that = this;
            return {
                allLocSales: 0,
                token: this.getCookie('token') || '',
                salesTit: "每日情况",
                areaTit: "地区统计",
                selectList: [
                    {id: 0, val: "月统计"},
                    {
                        id: 1,
                        val: "年统计"
                    }
                ],
                checkQueryIdx: 0,
                dateObj: {
                    date: this.getNowFormatDate(2).showDate,
                    paramsDate: this.getNowFormatDate(2).paramsDate
                },
                pickerVisible: new Date(),
                selectAreaType: 1,
                selectSalesType: 1,
                shopCnt: '-',
                addShopCnt: '-',
                hasSaleCnt: '-',
                parmasDate: '',
                SalesOptin: {
                    tooltip: {
                        show: true,
                        enterable: true,
                        trigger: "axis",
                        triggerOn: "click", //可点击
                        position: function (point, params, dom, rect, size) {
                            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
                            // 提示框位置
                            let x = 0; // x坐标位置
                            let y = 0; // y坐标位置

                            // 当前鼠标位置
                            let pointX = point[0];
                            let pointY = point[1];

                            // 外层div大小
                            // let viewWidth = size.viewSize[0];
                            // let viewHeight = size.viewSize[1];

                            // 提示框大小
                            let boxWidth = size.contentSize[0];
                            let boxHeight = size.contentSize[1];

                            // boxWidth > pointX 说明鼠标左边放不下提示框
                            if (boxWidth > pointX) {
                                x = 5;
                            } else {
                                // 左边放的下
                                x = pointX - boxWidth;
                            }

                            // boxHeight > pointY 说明鼠标上边放不下提示框
                            if (boxHeight > pointY) {
                                y = 5;
                            } else {
                                // 上边放得下
                                y = pointY - boxHeight;
                            }

                            return [x, 20];
                        },
                        formatter (params, ticket, callback) {
                            let res = `<p style="display: inline-block;margin: 10px 0 10px 10px">${that.checkSaleDate(params[0].name)}</p>`;
                            let pEl = '';
                            for (let i in params) {
                                that.parmasDate = params[i].data.rpt_date;
                                if (that.checkQueryIdx == 2) {
                                    pEl = '';
                                } else {
                                    pEl = '<p style="display: inline-block;margin: 10px 0 10px 10px">' + params[i].data.week + '</p>';
                                }
                                res +=
                                    '<p style="margin:10px 0 0 10px ">' +
                                    that.numFormat(params[i].value) +
                                    '</p></div>';
                            }
                            return res;
                        },
                        extraCssText:
                            "width:181px;height:87px;background:#FF0000;border-radius:4px;font-size:22px;"
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            interval: 5,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 680,
                        height: 322,
                        left: 35,
                        bottom: 60,
                    },
                    series: [
                        {
                            name: "全国",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#0000FF",
                                    lineStyle: {
                                        color: "#0000FF"
                                    }
                                }
                            },
                            symbolSize: 10
                        }
                    ]
                },
                locArr: [],
                AreaOptin: {
                    tooltip: {
                        trigger: 'axis',
                        extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            // formatter (value, index) {
                            //     let xAxisDataLen = 12;
                            //     return index == 0 ||
                            //     index == xAxisDataLen - 1 ||
                            //     index % 5 == 0
                            //         ? value
                            //         : "";
                            // },
                            interval: 5,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: true
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 710,
                        height: 386,
                        left: 20,
                    },
                    series: [
                        {
                            keys: '',
                            name: "北京",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#0968CE",
                                    lineStyle: {
                                        color: "#0968CE"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            keys: '',
                            name: "广东",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#54A64E",
                                    lineStyle: {
                                        color: "#54A64E"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            keys: '',
                            name: "天津",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#F4B61B",
                                    lineStyle: {
                                        color: "#F4B61B"
                                    }
                                }
                            },
                            symbolSize: 10
                        }
                    ]
                },
                cityOptin: {
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    show: false,
                                    formatter: '{d}%',
                                    position: 'inside',
                                    color: '#000'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: []
                        }
                    ],
                    color: []
                },
                salesCanvasSize: {
                    w: "37.5rem",
                    h: "25.8rem"
                },
                cityCanvasSize: {
                    w: "14rem",
                    h: "14rem"
                },
                AreaCanvasSize: {
                    w: "37.5rem",
                    h: "25rem"
                },
                Area2CanvasSize: {
                    w: "37.5rem",
                    h: "25rem"
                },
                SalesloadingType: false,
                SalesHasDataType: true,
                ArealoadingType: false,
                AreaHasDataType: true,
                CityHasDataType: true,
                bjSale: '',
                gdSale: '',
                tjSale: '',
                salesRankList: [],
                showRankFlag: false,
                showSalesFlag: false,
                gameList: [],
                areaList: [],
                hasQgPerm: false,
                hasCenter: false,
                cancelAjax1: null,
                cancelAjax2: null,
                cancelAjax3: null,
                showlocIndex: 3,
                colorArr: [],
                tips: '展开全部 >'
            };
        },
        filters: {
            sliceDate (val) {
                let str = '';
                return str = parseInt(val.split('/')[1]) + '月' + parseInt(val.split('/')[2]) + '日'
            },
            matchRdcId (id) {
                switch (id) {
                    case 0:
                        return '全国';
                    case 10:
                        return '北京';
                    case 20:
                        return '广东';
                    case 22:
                        return '天津';
                    default:
                        return '-';
                }
            },
        },
        methods: {
            /**
             * HSL颜色值转换为RGB
             * H，S，L 设定在 [0, 1] 之间
             * R，G，B 返回在 [0, 255] 之间
             *
             * @param H 色相
             * @param S 饱和度
             * @param L 亮度
             * @returns Array RGB色值
             */
            hslToRgb (H, S, L) {
                var R, G, B;
                if (+S === 0) {
                    R = G = B = L; // 饱和度为0 为灰色
                } else {
                    var hue2Rgb = function (p, q, t) {
                        if (t < 0) t += 1;
                        if (t > 1) t -= 1;
                        if (t < 1 / 6) return p + (q - p) * 6 * t;
                        if (t < 1 / 2) return q;
                        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                        return p;
                    };
                    var Q = L < 0.5 ? L * (1 + S) : L + S - L * S;
                    var P = 2 * L - Q;
                    R = hue2Rgb(P, Q, H + 1 / 3);
                    G = hue2Rgb(P, Q, H);
                    B = hue2Rgb(P, Q, H - 1 / 3);
                }
                return [Math.round(R * 255), Math.round(G * 255), Math.round(B * 255)];
            },
            // 获取随机HSL
            randomHsl () {
                var H = Math.random();
                var S = Math.random();
                var L = Math.random();
                return [H, S, L];
            },
            // 获取HSL数组
            getHslArray (cnt) {
                var HSL = [];
                var hslLength = cnt; // 获取数量
                for (var i = 0; i < hslLength; i++) {
                    var ret = this.randomHsl();

                    // 颜色相邻颜色差异须大于 0.25
                    if (i > 0 && Math.abs(ret[0] - HSL[i - 1][0]) < 0.25) {
                        i--;
                        continue; // 重新获取随机色
                    }
                    ret[1] = 0.7 + (ret[1] * 0.2); // [0.7 - 0.9] 排除过灰颜色
                    ret[2] = 0.4 + (ret[2] * 0.4); // [0.4 - 0.8] 排除过亮过暗色

                    // 数据转化到小数点后两位
                    ret = ret.map(function (item) {
                        return parseFloat(item.toFixed(2));
                    });

                    HSL.push(ret);
                }
                return HSL;
            },
            getPercent (num, total) {
                return (Math.round(num / total * 10000) / 100.00) >= 0 ? Math.round(num / total * 10000) / 100.00 + "%" : 0;// 小数点后两位百分比
            },
            showAllLoc () {
                if (this.showlocIndex == this.locArr.length) {
                    this.showlocIndex = 3;
                    this.tips = '展开全部 >'
                } else {
                    this.showlocIndex = this.locArr.length;
                    this.tips = '收起'
                }
            },
            // 选择查询类型
            checkQueryType (idx) {
                if (this.checkQueryIdx != idx) {
                    this.checkQueryIdx = idx;
                    if (idx == 0) {
                        this.salesTit = '每日情况'
                    } else {
                        this.salesTit = '每月情况'
                    }
                    this.setShowDateByType(idx);
                    this.setDateByType(idx);
                    this.getShopCnt();
                    this.getSales();
                    this.getAreaShops();
                }
            },
            // 设置时间格式
            setDateByType (idx) {
                if (idx == 0) {
                    this.dateObj.date = this.getNowFormatDate(2).showDate;
                    this.dateObj.paramsDate = this.getNowFormatDate(2).paramsDate;
                } else if (idx == 1) {
                    this.dateObj.date = this.getNowFormatDate(3).showDate;
                    this.dateObj.paramsDate = this.getNowFormatDate(3).paramsDate;
                }
            },
            //点击时间插件时对插件样式的操作
            setShowDateByType (idx) {
                if (idx == 0) {
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[0].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[1].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[2].style.display = "none";
                } else if (idx == 1) {
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[0].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[1].style.display = "none";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[2].style.display = "none";
                }
            },
            openPicker () {
                this.$refs.picker.open();
                this.setShowDateByType(this.checkQueryIdx);
            },
            // 全国中心
            setActiveBySelected (type) {
                if (type != this.selectAreaType) {
                    this.selectAreaType = type;
                    this.getShopCnt();
                    this.getSales();
                    this.getAreaShops();
                }
            },
            // 门店
            setClassNameBySelectSales (type) {
                if (type != this.selectSalesType) {
                    this.selectSalesType = type;
                    this.getShopCnt();
                    this.getSales();
                    this.getAreaShops();
                }
            },
            // 格式化获取的时间
            formatDate (date, type, showType = 0) {
                const y = date.getFullYear();
                let m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                let m2 = date.getMonth() + 1;
                // m2 = m2 < 10 ? "0" + m2 : m2;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                if (showType == 0) {
                    if (type == 1) {
                        return y + "年" + m2 + "月";
                    } else if (type == 2) {
                        return y + "年";
                    }
                } else {
                    if (type == 1) {
                        return y + "/" + m;
                    } else if (type == 2) {
                        return y;
                    }
                }
            },
            //获取当前时间
            getNowFormatDate (type) {
                let date = new Date();
                let seperator1 = "/";
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let month2 = date.getMonth() + 1;
                let strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                let currentDate = {
                    showDate: "",
                    paramsDate: ""
                };
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                if (type == 1) {
                    currentDate.showDate =
                        year + "年" + month2 + "月" + strDate + "日";
                    currentDate.paramsDate = year + "/" + month + "/" + strDate;
                } else if (type == 2) {
                    currentDate.showDate = year + "年" + month2 + "月";
                    currentDate.paramsDate = year + "/" + month;
                } else if (type == 3) {
                    currentDate.showDate = year + "年";
                    currentDate.paramsDate = year;
                }
                return currentDate;
            },
            //点击确定按钮之后
            handleConfirm () {
                // 输出格式化后的时间
                if (this.checkQueryIdx == 0) {
                    this.dateObj.date = this.formatDate(this.$refs.picker.value, 1);
                    this.dateObj.paramsDate = this.formatDate(this.$refs.picker.value, 1, 1);
                } else if (this.checkQueryIdx == 1) {
                    this.dateObj.date = this.formatDate(this.$refs.picker.value, 2);
                    this.dateObj.paramsDate = this.formatDate(this.$refs.picker.value, 2, 1);
                }
                this.getShopCnt();
                this.getSales();
                this.getAreaShops();
            },
            // 查询销售量和销售票数 GetSaleAmtAndCnt
            getShopCnt () {
                let that = this;
                let timeStr = this.dateObj.paramsDate.toString();
                this.shopCnt = '-';
                this.addShopCnt = '-';
                this.hasSaleCnt = '-';
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax1 === 'function') {
                    this.cancelAjax1()
                }
                this.$axios({
                    method: "post",
                    url: "web/GetShopCount",
                    data: {
                        token: this.token,
                        dateType: this.checkQueryIdx,
                        timeStr: timeStr,
                        area: this.selectAreaType
                    },
                    cancelToken: new CancelToken(function executor (c) {
                        that.cancelAjax1 = c
                    })
                }).then(res => {
                    let _data = res.data;
                    if (_data.code == 0) {
                        this.shopCnt = this.numFormat(_data.totalCount);
                        this.addShopCnt = this.numFormat(_data.addCount);
                        this.hasSaleCnt = this.numFormat(_data.saleCount);
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox('提示', _data.message);
                    }
                }).catch(err => {
                    if (!this.$axios.isCancel(err)) {
                        MessageBox('提示', '网络异常');
                    }
                });
            },
            //查询销量 GetDaySale
            getSales () {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax2 === 'function') {
                    this.cancelAjax2()
                }
                let timeStr = this.dateObj.paramsDate.toString();
                this.SalesOptin.xAxis.data = [];
                this.SalesOptin.series[0].data = [];
                let that = this;
                this.SalesloadingType = true;
                this.SalesHasDataType = false;
                this.$axios({
                    method: "post",
                    url: "web/GetShopCountByDay",
                    data: {
                        token: this.token,
                        dateType: this.checkQueryIdx,
                        timeStr: timeStr,
                        area: this.selectAreaType,
                        shopType: this.selectSalesType
                    },
                    cancelToken: new CancelToken(function executor (c) {
                        that.cancelAjax2 = c
                    })
                }).then(res => {
                    this.SalesloadingType = false;
                    let _data = res.data;
                    if (_data.code == 0) {
                        for (let i in _data.shopCountList) {
                            this.SalesOptin.xAxis.data.push(this.changeVal(_data.shopCountList[i].rpt_date));
                            this.SalesOptin.series[0].data.push(_data.shopCountList[i]);
                        }
                        if (this.SalesOptin.series[0].data.length == 0) {
                            this.SalesHasDataType = true;
                        }
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox('提示', _data.message);
                    }
                }).catch(err => {
                    this.SalesloadingType = false;
                    if (!this.$axios.isCancel(err)) {
                        this.ArealoadingType = false;
                        MessageBox('提示', '网络异常');
                    }
                });
            },
            //查询地区销量 GetLocalSale
            getAreaShops () {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax3 === 'function') {
                    this.cancelAjax3()
                }
                let that = this;
                let timeStr = this.dateObj.paramsDate.toString();
                this.AreaOptin.xAxis.data = [];
                this.AreaOptin.series[0].data = [];
                this.AreaOptin.series[1].data = [];
                this.AreaOptin.series[2].data = [];
                this.cityOptin.series[0].data = [];
                this.AreaOptin.series[0].keys = '';
                this.AreaOptin.series[1].keys = '';
                this.AreaOptin.series[2].keys = '';
                this.allLocSales = 0;
                this.locArr = [];
                this.ArealoadingType = true;
                this.AreaHasDataType = false;
                this.CityHasDataType = false;
                this.$axios({
                    method: "post",
                    url: "web/GetShopLocalCount",
                    data: {
                        token: this.token,
                        dateType: this.checkQueryIdx,
                        timeStr: timeStr,
                        area: this.selectAreaType,
                        shopType: this.selectSalesType
                    },
                    cancelToken: new CancelToken(function executor (c) {
                        that.cancelAjax3 = c
                    })
                }).then(res => {
                    this.ArealoadingType = false;
                    let _data = res.data;
                    if (_data.code == 0) {
                        let arr = this.getHslArray(_data.shopLocalList.length);
                        let rgbArray = this.getHslArray(_data.shopLocalList.length).map((item) => {
                            return this.hslToRgb.apply(this, item);
                        });
                        let colorArr = rgbArray.map((item) => {
                            return 'rgb(' + item.toString() + ')'

                        });
                        for (let i in _data.threeList) {
                            this.AreaOptin.xAxis.data.push(this.changeVal(_data.threeList[i].rpt_date));
                            if (_data.threeList[i].list[0]) {
                                this.AreaOptin.series[0].data.push(_data.threeList[i].list[0].value);
                                this.AreaOptin.series[0].name = _data.threeList[i].list[0].name;
                                this.AreaOptin.series[0].keys = _data.threeList[i].list[0].id;
                            }
                            if (_data.threeList[i].list[1]) {
                                this.AreaOptin.series[1].data.push(_data.threeList[i].list[1].value);
                                this.AreaOptin.series[1].name = _data.threeList[i].list[1].name;
                                this.AreaOptin.series[1].keys = _data.threeList[i].list[1].id;
                            }
                            if (_data.threeList[i].list[2]) {
                                this.AreaOptin.series[2].data.push(_data.threeList[i].list[2].value);
                                this.AreaOptin.series[2].name = _data.threeList[i].list[2].name;
                                this.AreaOptin.series[2].keys = _data.threeList[i].list[2].id;
                            }
                        }
                        for (let i in this.AreaOptin.series) {
                            if (_data.shopLocalList[0] && this.AreaOptin.series[i].keys == _data.shopLocalList[0].id) {
                                this.AreaOptin.series[i].itemStyle.normal.color = colorArr[0] || '#0968CE';
                                this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[0] || '#0968CE';
                            } else if (_data.shopLocalList[1] && this.AreaOptin.series[i].keys == _data.shopLocalList[1].id) {
                                this.AreaOptin.series[i].itemStyle.normal.color = colorArr[1] || '#54A64E';
                                this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[1] || '#54A64E';
                            } else if (_data.shopLocalList[2] && this.AreaOptin.series[i].keys == _data.shopLocalList[2].id) {
                                this.AreaOptin.series[i].itemStyle.normal.color = colorArr[2] || '#F4B61B';
                                this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[2] || '#F4B61B';
                            }
                        }
                        if (this.AreaOptin.series[0].data == 0 && this.AreaOptin.series[1].data == 0 && this.AreaOptin.series[2].data == 0) {
                            this.AreaHasDataType = true;
                        }
                        for (let i in _data.shopLocalList) {
                            this.allLocSales += parseInt(_data.shopLocalList[i].value);
                            this.locArr.push({
                                cnt: _data.shopLocalList[i].value,
                                name: _data.shopLocalList[i].name
                            })
                            this.cityOptin.series[0].data.push({
                                value: _data.shopLocalList[i].value,
                                name: _data.shopLocalList[i].name
                            })
                        }
                        if (this.cityOptin.series[0].data == 0) {
                            this.CityHasDataType = true;
                        }
                        this.cityOptin.color = colorArr;
                        this.colorArr = colorArr;
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox('提示', _data.message);
                    }
                }).catch(err => {
                    if (!this.$axios.isCancel(err)) {
                        this.ArealoadingType = false;
                        MessageBox('提示', '网络异常');
                    }
                });
            },
            // 日期转/
            changeVal (val) {
                let str = '';
                let result = '';
                str = val.slice(5).replace('/', '-');
                if (this.checkQueryIdx == 1) {
                    result = parseInt(str.split('-')[0]) + '月'
                } else {
                    result = parseInt(str.split('-')[0]) + '-' + parseInt(str.split('-')[1])
                }
                return result
            },
            //销售日期转事件
            checkSaleDate (date, num = 0) {
                let getDate = '';
                if (num == 1) {
                    getDate = date.slice(5).replace('/', '-');
                } else {
                    getDate = date;
                }
                let str = '';
                if (this.checkQueryIdx == 1) {
                    str = parseInt(getDate.split('-')[0]) + '月';
                } else {
                    str = parseInt(getDate.split('-')[0]) + '月' + parseInt(getDate.split('-')[1]) + '日';
                }
                return str
            },
            // 判断用户是否有全国权限
            checkQgParm () {
                this.$axios({
                    method: "post",
                    url: "web/GetUserPerm",
                    data: {
                        token: this.token,
                    }
                }).then(res => {
                    let _data = res.data;
                    if (_data.code == 0) {
                        let isPrem = _data.permList.join(',');
                        if (isPrem.indexOf('9') > -1) {
                            this.hasQgPerm = true;
                            if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                                this.selectAreaType = 1;
                                this.hasCenter = true;
                            } else {
                                this.selectAreaType = 0;
                                this.hasCenter = false;
                            }
                        } else {
                            this.hasQgPerm = false;
                            if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                                this.selectAreaType = 1;
                                this.hasCenter = true;
                            } else {
                                this.hasCenter = false;
                                MessageBox.alert(_data.message).then(action => {
                                    this.$router.push({path: '/'})
                                });
                            }
                        }
                        this.getShopCnt();
                        this.getSales();
                        this.getAreaShops();
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    }

                }).catch(err => {
                    MessageBox('提示', '网络异常');
                });
            },
        },
        mounted () {
            this.checkQgParm();
        }
    };
</script>
<style scoped>
    @import "./../assets/css/sales.css";
    @import "./../assets/css/daily.css";

    .dataBox .dataBox_0 {
        height: auto;
    }

    .dataBox .chart_desc .name_city {
        width: 8rem;
    }

    .dataBox .chart_desc .num_city {
        width: 12rem;
        text-align: left;
    }

    .addr3 {
        background: #cccccc;
    }

    .num_percent {
        width: 6rem;
    }

    .select-wrap {
        width: 17.5rem !important;
    }

    .select-topic .select-wrap .select-btn:nth-child(2) {
        border-right: none;
    }

    .sales-type {
        width: 33% !important;
        border-right: 0.2rem solid rgba(236, 236, 236, 1);
        box-sizing: border-box;
    }

    .sales-type:nth-of-type(4) {
        border-right: none;
    }

    .sales-text-bottom-line {
        width: 9.8rem !important;
    }

    .check2-line-run-1 {
        transform: translateX(.8rem) !important;
    }

    .check2-line-run-0 {
        transform: translateX(12.7rem) !important;
    }

    .check2-line-run-2 {
        transform: translateX(24.6rem) !important;
    }

    .dataBox .chart_desc div {
        line-height: 2.4rem;
        margin: 0 0 0 4rem;
    }
</style>